import React, { useEffect, useRef, useState } from "react";
import figuresFooter from "../images/figures-white.svg";

import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticQuery, graphql } from "gatsby";

import FigureA from "../images/footer/figure-a.svg";
import FigureB from "../images/footer/figure-b.svg";
import FigureC from "../images/footer/figure-c.svg";

import button from "../images/button.svg";
// import { Link } from "gatsby";

import "../css/footer.scss";

const Footer = (props) => {
  const footerInvert = props.footerInvert;
  const [footerLoaded, setFooterLoaded] = useState(false);
  const handleLoadFooter = () => setFooterLoaded(true);
  const footerRef = useRef();
  // if (imageBottle3.current.complete) setLoaded(true)
  const { t } = useTranslation();

  useEffect(() => {
    var footerHeight;
    if (footerRef.current) setFooterLoaded(true);
    setTimeout(() => {
      // console.log("---500---", document.getElementById("footer").clientHeight)
      // footerLoaded ? addFooterSpacer() : console.log("--not yet loaded--")
      footerLoaded ? addFooterSpacer() : console.log();
    }, 250);

    // setTimeout(() => {
    //   console.log("---1000---", document.getElementById("footer").clientHeight)
    //   // footerLoaded ? addFooterSpacer() : console.log("--not yet loaded--")
    // }, 1000)

    // setTimeout(() => {
    //   console.log("---1500---", document.getElementById("footer").clientHeight)
    //   // footerLoaded ? addFooterSpacer() : console.log("--not yet loaded--")
    // }, 1500)

    setTimeout(() => {
      // console.log("---3000---", document.getElementById("footer").clientHeight)
    }, 3000);

    function addFooterSpacer() {
      var documentHeight = document.body.clientHeight;
      var windowHeight = window.innerHeight;
      footerHeight = document.getElementById("footer").clientHeight;
      var footerTopPadding = footerHeight - windowHeight;

      if (windowHeight > footerHeight) {
        // console.log(
        //   "--------windowHeight > footerHeight ",
        //   footerHeight - windowHeight
        // )

        document.getElementsByTagName("main")[0].style.marginBottom =
          footerHeight + "px";
      } else {
        // console.log(
        //   "--------windowHeight <= footerHeight ",
        //   footerHeight - windowHeight
        // )
        document.getElementsByTagName("main")[0].style.marginBottom =
          footerHeight + "px";
        document.getElementById("footer").style.transform =
          "translateY(" + footerTopPadding + "px)";
      }
    }
  });

  return (
    <>
      <footer
        id="footer"
        onLoad={handleLoadFooter}
        ref={footerRef}
        className={footerInvert}
      >
        <div className="footer w-full mx-auto page-footer footer-btm">
          {/* <div className="subscription tracking-wide flex flex-wrap text-sm sm:text-xl md:text-2xl">
            <div className="footer-row pb-5 sm:pb-0 w-full sm:w-1/3 hidden lg:block">
              письма из ясной:
            </div>
            <div className="footer-row w-1/2">
              <input
                className="md:hidden subscription-mail appearance-none bg-transparent border-none w-full mr-3 py-1 leading-tight focus:outline-none"
                id="subscriptionEmail"
                type="email"
                placeholder="ваша почта:"
              />
              <input
                className="subscription-mail appearance-none bg-transparent border-none w-full mr-3 py-1 leading-tight focus:outline-none"
                id="subscriptionEmail"
                type="email"
                placeholder="ваша почта:"
              />
            </div>

            <div className="footer-row w-1/2 sm:w-1/6">
              
              <img className="subscription-button" src={button} alt="" />
              
            </div>
          </div> */}

          <div className="flex subs-footer-wrapper">
            <div className="menu-side__input-label hidden md:block md:w-1/3">
              {t("subscribe")}
            </div>

            {/*  */}
            <div className="w-full md:w-2/3 md:pl-2">
              <div className="menu-side__subs_footer">
                {/* or */}
                <div className="w-full md:hidden">
                  <input
                    className="menu-side__input menu-side__input_footer appearance-none bg-transparent border-none w-full mr-3 py-1 leading-tight focus:outline-none"
                    id="subscriptionEmail"
                    type="email"
                    placeholder={t("yourEmail")}
                  />
                </div>
                {/* or */}
                <div className="hidden md:block md:w-full">
                  <input
                    className="menu-side__input menu-side__input_footer appearance-none bg-transparent border-none w-full mr-3 py-1 leading-tight focus:outline-none"
                    id="subscriptionEmail"
                    type="email"
                    placeholder={t("yourEmail")}
                  />
                </div>
                {/* or */}

                <div className="subscription-menu_link">
                  <Link to="/" className="">
                    <svg
                      className="subscription-button subscription-button_footer"
                      width={22}
                      height={22}
                      viewBox="0 0 22 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3554 3.64455C22.4149 7.70396 22.4149 14.296 18.3554 18.3554C14.2976 22.4133 7.74725 22.4167 3.64362 18.3545C-0.414851 14.295 -0.41454 7.70364 3.64455 3.64455C7.70395 -0.414853 14.296 -0.414853 18.3554 3.64455Z"
                        strokeWidth={1.2}
                      />
                    </svg>
                    {/* <img
                        className="subscription-button"
                        src={button}
                        alt=""
                      /> */}
                  </Link>
                </div>
              </div>
            </div>
            {/*  */}
          </div>

          <div className="row links-footer tracking-wide flex flex-wrap sm:text-base">
            <div className="col footer-row w-1/3">
              <Link
                to="/contact"
                className="link-underline link-underline_footer"
              >
                {t("contacts")}
              </Link>
            </div>
            <div className="col footer-row w-1/3 pb-4 sm:pb-0">
              <a
                href="https://www.instagram.com/yasnaya.ru"
                className=""
                target="_blank"
                rel="noreferrer"
                className="link-underline link-underline_footer"
              >
                {t("instagram")}
              </a>
            </div>

            <div className="col footer-row w-1/3">
              <a
                href="https://facebook.com/yasnaya.ru"
                className=""
                target="_blank"
                rel="noreferrer"
                className="link-underline link-underline_footer"
              >
                {t("facebook")}
              </a>
            </div>
          </div>

          <div className="row footer-row footer-copyright flex">
            <div className="col w-1/3"></div>
            <div className="col trademark w-1/3">
              {t("tm")}
              <br />
              {t("yasnGardens")}
            </div>
            <div className="col studiotransformer w-1/3">
              {t("madeByStudio")}
              <br />
              <a
                href="https://studiotransformer.ru"
                target="_blank"
                rel="noreferrer"
                className="link-underline_footer-exp"
              >
                {t("transformer")}
              </a>
            </div>
          </div>

          {/* <img className="w-full figures" src={figuresFooter} alt="footer" /> */}
          <div className="row flex">
            {/* <div id="cfig" className="container-figures flex -mx-2"> */}
            <div className="col figure-wrapper w-1/3">
              <img id="FIGUREA" className="" src={FigureA} alt="figures" />
            </div>
            <div className="col figure-wrapper w-1/3">
              <img id="FIGUREB" className="" src={FigureB} alt="figures" />
            </div>
            <div className="col figure-wrapper w-1/3">
              <img id="FIGUREC" className="" src={FigureC} alt="figures" />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["footer"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
