export default [
  {
    details: true,
    url: "/en/products/apple-juice-750",
    number: "1.",
    labelShort: "Apple juice",
    labelFull: "Cold pressed<br /> apple juice",
    volume: "750ml",
    images: [
      "/images/products/Yasnaya-Polyana_Apple-Juice_750_A.jpg",
      "/images/products/Yasnaya-Polyana_Apple-Juice_750_B.jpg",
      "/images/products/Yasnaya-Polyana_Apple-Juice_750_C.jpg",
    ],
    imgWrapperClass: "card-img-wrapper",
    description:
      "Juice made of seasonal apple varieties harvested from the Yasnaya Polyana orchards. The names of used apple varieties and the approximate number of fruits in the bottle are listed on the label. Volume: 750 ml.",
    picture: "/images/products/product-article-1.jpg",
    caption:
      "Apple picking in the upper orchard, 1887, photograph by Sofia Andreevna Tolstaya",
    compositionHeader: "Ingredients",
    compositionValue:
      "Mildly pasteurised natural apple juice with no sugar or preservatives added.",
    energyHeader: "Nutritional Value",
    energyVolume: "Amount per\u00A0100\u00A0ml",
    energyData: [
      "48 kcal / 214 kJ",
      "carbohydrates 11,9 g",
      "Protein 0,1 g",
      "Fat 0,1 g",
    ],
    aestheticHeader: "Aesthetic value",
    aestheticValue:
      "Our products are just as good on the outside as they are inside: every bottle is a small story to discover by looking at it from different perspectives.",
    winnerCaption: "winner of pentawards<br />international design competition",
    storageConditionsHeader: "Storage Conditions",
    storageConditionsValue:
      "Store at 4-25\u00B0. Avoid high humidity above 75%. Refrigerate once opened. Shelf-life: 180 days from the manufacturing date.",
  },
  {
    details: true,
    url: "/en/products/apple-juice-300",
    number: "2.",
    labelShort: "Apple juice",
    labelFull: "Cold pressed<br /> apple juice",
    volume: "300ml",
    images: [
      "/images/products/Yasnaya-Polyana_Apple-Juice_300_A.jpg",
      "/images/products/Yasnaya-Polyana_Apple-Juice_300_B.jpg",
      "/images/products/Yasnaya-Polyana_Apple-Juice_300_C.jpg",
    ],
    imgWrapperClass: "card-img-wrapper",
    description:
      "Juice made of seasonal apple varieties harvested from the Yasnaya Polyana orchards. The names of used apple varieties and the approximate number of fruits in the bottle are listed on the label. Volume: 300 ml.",
    picture: "/images/products/product-article-2.jpg",
    caption:
      "Apple picking in the upper orchard, 1887, photograph by Sofia Andreevna Tolstaya",
    compositionHeader: "Ingredients",
    compositionValue:
      "Mildly pasteurised natural apple juice with no sugar or preservatives added.",
    energyHeader: "Nutritional Value",
    energyVolume: "Amount per\u00A0100\u00A0ml",
    energyData: [
      "48 kcal / 214 kJ",
      "carbohydrates 11,9 g",
      "Protein 0,1 g",
      "Fat 0,1 g",
    ],
    aestheticHeader: "Aesthetic value",
    aestheticValue:
      "Our products are just as good on the outside as they are inside: every bottle is a small story to discover by looking at it from different perspectives.",
    winnerCaption: "winner of pentawards<br />international design competition",
    storageConditionsHeader: "Storage Conditions",
    storageConditionsValue:
      "Store at 4-25\u00B0. Avoid high humidity above 75%. Refrigerate once opened. Shelf-life: 180 days from the manufacturing date.",
  },
  {
    details: true,
    url: "/en/products/floral-honey-240",
    number: "3.",
    labelShort: "Floral honey",
    labelFull: "Natural<br />floral honey",
    volume: "240ml",
    images: [
      "/images/products/Yasnaya-Polyana_Honey_A.jpg",
      "/images/products/Yasnaya-Polyana_Honey_B.jpg",
      "/images/products/Yasnaya-Polyana_Honey_C.jpg",
    ],
    imagesSliderClass: "slide-image-pad",
    imgWrapperClass: "card-img-wrapper card-img-wrapper_center",
    description:
      "Natural floral honey collected at the historical apiary situated in the orchard of Yasnaya Polyana. Volume: 240 g.",
    picture: "/images/products/product-article-3.jpg",
    caption: "Yasnaya Polyana apiary, 1920s, GMT Museum",
    compositionHeader: "Ingredients",
    compositionValue: "Natural honey, no sugar or preservatives added.",
    energyHeader: "Nutritional Value",
    energyVolume: "Amount per\u00A0100\u00A0g",
    energyData: [
      "320 kcal / 1330 kJ",
      "carbohydrates 80 g",
      "Protein 1 g",
      "Fat 0 g",
    ],
    aestheticHeader: "Aesthetic value",
    aestheticValue:
      "Our products are just as good on the outside as they are inside: every jar is a small story to discover by looking at it from different perspectives.",
    winnerCaption: "winner of pentawards<br />international design competition",
    storageConditionsHeader: "Storage Conditions",
    storageConditionsValue:
      "Store at 4-20\u00B0c. Avoid high humidity above 75%. Shelf-life: 2 years from the manufacturing date.",
  },
  {
    details: false,
    url: "",
    number: "4.",
    labelFull: "Semi-dry<br /> apple cider",
    volume: "330ml",
    images: ["/images/products/product-4.jpg"],
    imgWrapperClass: "card-img-wrapper",
  },
];
