export default [
  {
    details: true,
    url: "/products/apple-juice-750",
    number: "1.",
    labelShort: "Яблочный сок",
    labelFull: "яблочный сок<br />прямого отжима",
    volume: "750 мл",
    images: [
      "/images/products/Yasnaya-Polyana_Apple-Juice_750_A.jpg",
      "/images/products/Yasnaya-Polyana_Apple-Juice_750_B.jpg",
      "/images/products/Yasnaya-Polyana_Apple-Juice_750_C.jpg",
    ],
    imgWrapperClass: "card-img-wrapper",
    description:
      "Сок из сезонных сортов яблок, собранных в яснополянских садах. На этикетке перечислены сорта яблок и указано примерное количество плодов в каждой бутылке. Объем 750 мл.",
    picture: "/images/products/product-article-1.jpg",
    caption: "Сбор яблок в верхнем саду 1887, фотография Софьи Толстой",
    compositionHeader: "Состав",
    compositionValue:
      "Натуральный яблочный сок прямого отжима, щадящей пастеризации, без сахара и консервантов.",
    energyHeader: "Энергетическая ценность",
    energyVolume: "на 100 мл",
    energyData: [
      "48 ккал / 214 кДж",
      "Углеводы 11,9 г",
      "Белки 0,1 г",
      "Жиры 0,1 г",
    ],
    aestheticHeader: "Эстетическая ценность",
    aestheticValue:
      "Наши соки также красивы снаружи, как и вкусны внутри: каждая бутылка — это небольшая история, которую интересно разглядывать со всех сторон.",
    winnerCaption: "Победитель международного<br />конкурса дизайна Pentawards",
    storageConditionsHeader: "Условия хранения",
    storageConditionsValue:
      "При температуре от +4\u00B0c до +25\u00B0c и относительной влажности воздуха до 75%, срок хранения 180 дней с даты производства.",
  },
  {
    details: true,
    url: "/products/apple-juice-300",
    number: "2.",
    labelShort: "Яблочный сок",
    labelFull: "яблочный сок<br />прямого отжима",
    volume: "300 мл",
    images: [
      "/images/products/Yasnaya-Polyana_Apple-Juice_300_A.jpg",
      "/images/products/Yasnaya-Polyana_Apple-Juice_300_B.jpg",
      "/images/products/Yasnaya-Polyana_Apple-Juice_300_C.jpg",
    ],
    imgWrapperClass: "card-img-wrapper",
    description:
      "Сок из сезонных сортов яблок, собранных в яснополянских садах. На этикетке перечислены сорта яблок и указано примерное количество плодов в каждой бутылке. Объем 300 мл.",
    picture: "/images/products/product-article-2.jpg",
    caption: "Сбор яблок в верхнем саду 1887, фотография Софьи Толстой",
    compositionHeader: "Состав",
    compositionValue:
      "Натуральный яблочный сок прямого отжима, щадящей пастеризации, без сахара и консервантов.",
    energyHeader: "Энергетическая ценность",
    energyVolume: "на 100 мл",
    energyData: [
      "48 ккал / 214 кДж",
      "Углеводы 11,9 г",
      "Белки 0,1 г",
      "Жиры 0,1 г",
    ],
    aestheticHeader: "Эстетическая ценность",
    aestheticValue:
      "Наши соки также красивы снаружи, как и вкусны внутри: каждая бутылка — это небольшая история, которую интересно разглядывать со всех сторон.",
    storageConditionsHeader: "Условия хранения",
    storageConditionsValue:
      "При температуре от +4\u00B0c до +25\u00B0c и относительной влажности воздуха до 75%, срок хранения 180 дней с даты производства.",
  },
  {
    details: true,
    url: "/products/floral-honey-240",
    number: "3.",
    labelShort: "Цветочный мед",
    labelFull: "Натуральный<br />цветочный мед",
    volume: "240 мл",
    images: [
      "/images/products/Yasnaya-Polyana_Honey_A.jpg",
      "/images/products/Yasnaya-Polyana_Honey_B.jpg",
      "/images/products/Yasnaya-Polyana_Honey_C.jpg",
    ],
    imagesSliderClass: "slide-image-pad",
    imgWrapperClass: "card-img-wrapper card-img-wrapper_center",
    description:
      "Натуральный цветочный мед, собранный на исторической пасеке, расположенной на территории усадьбы Ясная Поляна. Объем 240 г.",
    picture: "../images/products/product-article-3.jpg",
    caption: "Яснополянская пасека, 1920-е годы, музей ГМТ",
    compositionHeader: "Состав",
    compositionValue: "Натуральный пчелиный мед, без сахара и консервантов.",
    energyHeader: "Энергетическая ценность",
    energyVolume: "на 100 гр",
    energyData: [
      "320 ккал / 1330 кДж",
      "Углеводы 80 г",
      "Белки 1 г",
      "Жиры 0 г",
    ],
    aestheticHeader: "Эстетическая ценность",
    aestheticValue:
      "Наши продукты также хороши снаружи, как и внутри: каждая баночка — это небольшая история, которую интересно разглядывать со всех сторон.",
    storageConditionsHeader: "Условия хранения",
    storageConditionsValue:
      "При температуре от +4\u00B0c до +20\u00B0c и относительной влажности воздуха до 70%, срок хранения 2 года с даты производства.",
  },
  {
    details: false,
    url: "",
    number: "4.",
    labelFull: "полусухой<br />яблочный сидр",
    volume: "330 мл",
    images: ["/images/products/product-4.jpg"],
    imgWrapperClass: "card-img-wrapper",
  },
];
