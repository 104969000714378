import React from "react";
import { Link } from "gatsby";
import ProductSoonIconRu from "../images/products/product-soon-icon-ru.svg";
import ProductSoonIconEn from "../images/products/product-soon-icon-en.svg";

function CardItem(props) {
  const data = props.data;
  var ProductSoonIcon;
  {
    data.lang == "ru"
      ? (ProductSoonIcon = ProductSoonIconRu)
      : (ProductSoonIcon = ProductSoonIconEn);
  }
  return (
    <>
      <div className="card-number">{data.number}</div>
      <div className="card-content">
        <div className={data.imgWrapperClass}>
          <div className="card-img">
            <img src={data.images[0]} alt="Product" />
          </div>
        </div>
        <div className="row card-body -mx-4">
          <div
            className="col w-2/3 card-body__text card-body__text_left"
            dangerouslySetInnerHTML={{ __html: data.labelFull }}
          ></div>
          <div className="col w-1/3 card-body__text">{data.volume}</div>
        </div>
        {!data.details && (
          <div className="card-img-soon w-1/3">
            <img src={ProductSoonIcon} alt="" />
          </div>
        )}
      </div>
    </>
  );
}

function ProductCard(props) {
  return (
    <div className="card-wrapper card-product flex w-full md:w-1/2 md:px-4">
      {props.details ? (
        <Link to={props.url} className="card card-product__link">
          <CardItem data={props}></CardItem>
        </Link>
      ) : (
        <div className="card card-product__link card-soon">
          <CardItem data={props}></CardItem>
        </div>
      )}
    </div>
  );
}

export default ProductCard;
