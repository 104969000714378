import React from "react";

// import cards from "../components/ProductData";
import ProductCard from "../components/ProductCard";

const productCards = (props) => {
  // console.log("------props", props);
  return (
    <div className="row-products box box-products mx-auto">
      <div className="cards flex flex-wrap md:-mx-4">
        {props.cards.map((card) => (
          <ProductCard
            key={card.number}
            details={card.details}
            url={card.url}
            number={card.number}
            labelFull={card.labelFull}
            images={card.images}
            imgWrapperClass={card.imgWrapperClass}
            volume={card.volume}
            lang={props.lang}
          />
        ))}
      </div>
    </div>
  );
};

export default productCards;
