import React from "react";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import "../css/products.scss";
import ProductCards from "../components/ProductCards";

import cardsRu from "../components/ProductDataRu";
import cardsEn from "../components/ProductDataEn";
import { Helmet } from "react-helmet";

import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const Products = () => {
  const { t } = useTranslation();
  const { language, languages, originalPath } = useI18next();
  var cards;
  {
    language == "ru" ? (cards = cardsRu) : (cards = cardsEn);
  }

  return (
    <Layout>
      {/* <Header /> */}
      <Helmet>
        <title>{t("header")}</title>
      </Helmet>
      <main>
        <div className="page page-markets">
          <div className="page-title w-2/3 md:w-auto">
            <div className="page-title__name">{t("title")}</div>
          </div>

          <div className="page-header">
            <h1>{t("header")}</h1>
          </div>

          <div className="slogan md:flex md:w-1/2">{t("slogan")}</div>

          <ProductCards cards={cards} lang={language} />
        </div>
      </main>
      <Footer />
    </Layout>
  );
};

export default Products;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["products", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
